<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <div class="">
      <v-card>
        <v-img
          height="400"
          :src="school.image"
        ></v-img>
        <v-card-text class="py-4">
          <h2 class="font-weight-bold mb-2">
            {{ school.name }}
          </h2>
          <!-- <div class="d-flex mb-2">
            <div class="d-flex align-center mb-2">
              <v-img
                :src="icons.iconUserOctagon"
                width="20"
              ></v-img>
              <div class="mx-4">
                <h4>Kepala Sekolah</h4>
                <span>{{ school.headmaster.name }}</span>
                <span>Yuda</span>
              </div>
              <v-chip color="primary" small @click="detailHeadmaster(school.headmaster.uuid)">Lihat Detail</v-chip>
            </div>
          </div> -->
          <div class="d-flex mb-2">
            <div class="d-flex align-center mb-2">
              <v-img
                :src="icons.iconLocation"
                width="20"
              ></v-img> <span class="ml-4">{{ school.place.address }}</span>
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="d-flex align-center mb-2">
              <v-img
                :src="icons.iconClip"
                width="20"
              ></v-img> <span class="ml-4">link</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-row class="mt-2">
        <v-col
          v-for="card in cards"
          :key="card.title"
          md="3"
          lg="3"
          cols="12"
        >
          <v-card>
            <v-card-text class="d-flex">
              <div class="mr-4">
                <v-avatar :color="card.color">
                  <div>
                    <v-img
                      sizes="20"
                      :src="card.icon"
                    ></v-img>
                  </div>
                </v-avatar>
              </div>
              <div>
                <h3 class="font-weight-medium">
                  {{ card.title }}
                </h3>
                <h2 class="font-weight-bold mb-2">
                  {{ card.total }}
                </h2>
                <router-link
                  class="text-decoration-none"
                  :to="{ name: card.to}"
                >
                  Lihat Detail
                </router-link>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
        >
          <v-card>
            <v-card-text>
              <div class="d-flex align-center justify-space-between">
                <h3 class="text-subtitle-1 black--text font-weight-bold">Absensi Siswa</h3>

                <div>
                  <v-autocomplete
                    :label="dateNow"
                    outlined
                    :items="date"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details="none"
                    @change="handleFilter($event, 'attendanceStudent')"
                  ></v-autocomplete>
                </div>
              </div>
              <student-attendance-ratio :count="countStudentAttendance"></student-attendance-ratio>
            </v-card-text>
            <v-card-actions class="justify-center">
              <router-link
                class="text-decoration-none"
                :to="{name:'school-headdepartment-student-attendance'}"
              >
                Lihat Detail
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
        >
          <v-card>
            <v-card-text>
              <div class="d-flex align-center justify-space-between">
                <h3 class="text-subtitle-1 black--text font-weight-bold">Absensi Guru</h3>

                <div>
                  <v-autocomplete
                    :label="dateNow"
                    outlined
                    :items="date"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details="none"
                    @change="handleFilter($event, 'attendanceTeacher')"
                  ></v-autocomplete>
                </div>
              </div>
              <teacher-attendance-ratio :count="countTeacherAttendance"></teacher-attendance-ratio>
            </v-card-text>
            <v-card-actions class="justify-center">
              <router-link
                class="text-decoration-none"
                :to="{name:'school-headdepartment-teacher-attendance'}"
              >
                Lihat Detail
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <ModalDialog
      :visible="modalDialog"
      :width="800"
      header="DATA LENGKAP KEPALA SEKOLAH"
      :hidden-actions="false"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <div class="d-flex justify-center mb-2">
          <div>
            <v-img
              width="150"
              src="@/assets/images/avatars/ari.png"
            ></v-img>
          </div>
        </div>
        <div v-if="!isLoadingData">
          <div
            v-for="data,index in datas"
            :key="index"
            class="d-flex py-3 px-2"
            style="border-bottom: 1px solid #ddd"
          >
            <div style="width: 20%">
              {{ data.title }}
            </div>
            <div class="ml-8">
              : {{ data.content }}
            </div>
          </div>
        </div>
        <div v-else class="d-flex justify-center align-center py-10">
          <v-progress-circular
          indeterminate
          :size="50"
          color="primary"
        ></v-progress-circular>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import iconClip from '@/assets/icons/clip.svg'
import iconLocation from '@/assets/icons/location.svg'
import iconUserOctagon from '@/assets/icons/user-octagon.svg'
import iconHouse from '@/assets/icons/white/courthouse.svg'
import iconMonitor from '@/assets/icons/white/monitor.svg'
import iconBank from '@/assets/icons/white/receipt-2.svg'
import iconUser from '@/assets/icons/white/user.svg'
import ModalDialog from '@/views/components/ModalDialog.vue'
import { mdiArrowLeft, mdiDomain } from '@mdi/js'
import StudentAttendanceRatio from './StudentAttendanceRatio.vue'
import TeacherAttendanceRatio from './TeacherAttendanceRatio.vue'

export default {
  name: 'SchoolDataDetail',
  components: {
    StudentAttendanceRatio,
    TeacherAttendanceRatio,
    ModalDialog,
  },
  data() {
    return {
      school: {
        place: {},
      },
      school_uuid: '',
      icons: {
        mdiArrowLeft,
        iconLocation,
        iconUser,
        iconHouse,
        iconUserOctagon,
        iconClip,
        iconMonitor,
        iconBank,
        mdiDomain,
      },
      detail: {},
      total_student: 0,
      cards: [
        {
          title: 'Total Siswa',
          total: 0,
          icon: iconUser,
          color: '#EC008C',
          to: 'school-headdepartment-detail-student',
        },
        {
          title: 'Total Guru',
          total: 0,
          icon: iconUser,
          color: '#7D2B8B',
          to: 'school-headdepartment-detail-teacher',
        },
        {
          title: 'Total Sarpras',
          total: 0,
          icon: iconHouse,
          color: '#372B8B',
          to: 'school-headdepartment-detail-sarpras',
        },
        {
          title: 'Rehab Sarpras',
          total: 0,
          icon: iconHouse,
          color: '#458DB8',
          to: 'school-headdepartment-detail-sarpras-rehab',
        },
      ],
      service: 'school',
      date: [
        {
          id: `01-${new Date().getFullYear()}`,
          name: `January ${new Date().getFullYear()}`,
        },
        {
          id: `02-${new Date().getFullYear()}`,
          name: `February ${new Date().getFullYear()}`,
        },
        {
          id: `03-${new Date().getFullYear()}`,
          name: `March ${new Date().getFullYear()}`,
        },
        {
          id: `04-${new Date().getFullYear()}`,
          name: `April ${new Date().getFullYear()}`,
        },
        {
          id: `05-${new Date().getFullYear()}`,
          name: `May ${new Date().getFullYear()}`,
        },
        {
          id: `06-${new Date().getFullYear()}`,
          name: `June ${new Date().getFullYear()}`,
        },
        {
          id: `07-${new Date().getFullYear()}`,
          name: `July ${new Date().getFullYear()}`,
        },
        {
          id: `08-${new Date().getFullYear()}`,
          name: `August ${new Date().getFullYear()}`,
        },
        {
          id: `09-${new Date().getFullYear()}`,
          name: `September ${new Date().getFullYear()}`,
        },
        {
          id: `10-${new Date().getFullYear()}`,
          name: `October ${new Date().getFullYear()}`,
        },
        {
          id: `11-${new Date().getFullYear()}`,
          name: `November ${new Date().getFullYear()}`,
        },
        {
          id: `12-${new Date().getFullYear()}`,
          name: `December ${new Date().getFullYear()}`,
        },
      ],
      datas: [
        { title: 'NIK', keyName: 'nis', content: 'nik' },
        { title: 'NIP', keyName: 'nisn', content: 'nip' },
        { title: 'Nama Lengkap', keyName: 'name', content: 'name' },
        { title: 'Gelar Pertama', keyName: 'first_degree', content: 'first_degree' },
        { title: 'Gelar Terakhir', keyName: 'last_degree', content: 'last_degree' },
        { title: 'Tanggal Lahir', keyName: 'date_of_birth', content: 'date_of_birth' },
        { title: 'Tempat Lahir', keyName: 'place_of_birth', content: 'place_of_birth' },
        { title: 'Jenis Kelamin', keyName: 'gender', content: 'gender' },
        { title: 'Agama', keyName: 'religion', content: 'religion' },
        { title: 'Status Pernikahan', keyName: 'marital_status', content: 'marital_status' },
        { title: 'Telepon Rumah', keyName: 'phone_house', content: 'phone_house' },
        { title: 'No HP', keyName: 'phone', content: 'phone' },
        { title: 'Tanggal Kerja', keyName: 'date_of_work', content: 'date_of_work' },
        { title: 'Status Pekerjaan', keyName: 'status_work', content: 'status_work' },
      ],
      countStudentAttendance: [0, 0, 0, 0],
      countTeacherAttendance: [0, 0, 0, 0],
      paramsDate: '',
      dateNow: '',
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      modalDialog: false,
      isLoadingData: false,
    }
  },
  async mounted() {
    this.getSchool(this.$route.params.uuid)
    this.getSchoolUuid()
    this.dateNow = `${this.monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
    this.checkFirstParams()
    await this.getDetail({ date: this.paramsDate })
  },
  methods: {
    returnBack() {
      this.$router.back()
    },
    checkFirstParams() {
      this.date.forEach(item => {
        if (item.name === this.dateNow) this.paramsDate = item.id
      })
    },
    async getChartFund(params = {}) {
      await this.$services.ApiServices.listParams('headdepartment', this.school_uuid, { ...params }).then(
        ({ data }) => {
          this.countFund = Object.values(data.data.fund)
        },
      )
    },
    async getChartAttendance(params = {}) {
      await this.$services.ApiServices.getParams('headdepartment', this.school_uuid, { ...params }).then(({ data }) => {
        this.countStudentAttendance = Object.values(data.data.absen)
        // this.countTeacherAttendance = Object.values(data.data.absen_employee)
      })
    },
    async getChartAttendanceTeacher(params = {}) {
      await this.$services.ApiServices.getParams('headdepartment', this.school_uuid, { ...params }).then(({ data }) => {
        // this.countStudentAttendance = Object.values(data.data.absen)
        this.countTeacherAttendance = Object.values(data.data.absen_employee)
      })
    },
    async handleFilter(id, type) {
      if (type === 'attendanceStudent') {
        await this.getChartAttendance({ date: id })

        return
      } else if (type === 'attendanceTeacher') {
        await this.getChartAttendanceTeacher({ date: id })

        return
      }
      await this.getChartFund({ date: id })
    },
    async getSchool(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.school = data.data
      })
    },
    getSchoolUuid() {
      this.school_uuid = this.$route.params.uuid
    },
    async getDetail(params = {}) {
      await this.$services.ApiServices.getParams('headdepartment', this.school_uuid, { ...params }).then(({ data }) => {
        this.detail = data.data
        this.countStudentAttendance = Object.values(this.detail.absen)
        this.countTeacherAttendance = Object.values(this.detail.absen_employee)
        this.countFund = Object.values(this.detail.fund)
        this.cards[0].total = data.data.count_student
        this.cards[1].total = data.data.count_teacher
        this.cards[2].total = data.data.count_sarpras
        this.cards[3].total = data.data.count_sarpras_rehab
      })
    },
    async detailHeadmaster(uuid) {
      this.modalDialog = true
      this.isLoadingData = true
      await this.$services.ApiServices.get('employee', uuid).then(({ data }) => {
        Object.keys(data.data).forEach((key, el) => {
          this.datas.forEach(el => {
            // console.log(el)
            if (el.keyName === key) {
              el.content = data.data[key]
            }
          })
        })
      })
      this.isLoadingData = false
    },
  },
}
</script>

<style>
</style>
